<template>
  <div class="comments">
    <TextareaRich class="message-box" :placeholder="$locale['report']['add_answer']" @input="inputMessage" />
    <div tcenter padding-top v-if="loading"><iconic name="gspinner" /></div>
    <Confirm :confirm="confirm" @cancel="commentCancel" @accept="commentAccept" v-else-if="message" />
    <div class="comments-list">
      <MessageBubble v-for="comment in comments" :key="comment._id" :data="{ user: comment.user, date: comment.date, message: comment.comment }" />
      <LoadMoreButton :hasMore="hasMore" @more="$emit('more')" :label="$locale['view_more']" :loading="loadingMore" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["loading", "comments", "total", "loadingMore"],
  components: {
    MessageBubble: () => import("../widgets/MessageBubble.vue"),
  },
  data: function() {
    return {
      target: null,
      message: "",
      confirm: {
        cancel: {
          label: this.words("cancel"),
          icon: "times",
        },
        accept: {
          label: this.words("accept"),
          icon: "check",
        },
      },
    };
  },
  methods: {
    inputMessage: function(e) {
      this.$emit("input", e);
      this.target = e.target;
      this.message = e.target.innerHTML;
    },
    commentCancel: function() {
      this.message = "";
      this.target.innerHTML = "";
      this.$emit("cancel");
    },
    commentAccept: function() {
      this.$emit("accept");
    },
  },
  computed: {
    hasMore: function() {
      return this.comments && this.total > this.comments.length;
    },
  },
};
</script>

<style lang="scss">
.comments {
  padding: 0 $mpadding $mpadding $mpadding;
  border-top: solid 1px $alto;
  .textarea-rich-box {
    border-radius: $mpadding;
    background-color: rgba(9, 30, 66, 0.01);
    min-height: auto;
    padding: $mpadding;
    margin: $mpadding 0 0 0;
  }
  .confirm {
    margin: $mpadding 0 0 0;
  }
  .load-more {
    padding: $mpadding 0 0 0;
    .sec-button {
      display: inline-flex;
    }
  }
}
</style>
